import axios from 'axios';
import { toast } from 'react-toastify';

// Determine baseURL dynamically based on the hostname
const getBaseURL = () => {
  const hostname = window.location.hostname;

  // Map domains to their corresponding backend URLs
  const tenantMapping = {
    'uic.annaguidance.ai': 'https://api.uic.annaguidance.ai', // UIC tenant
    'ndm.annaguidance.ai': 'https://api.ndm.annaguidance.ai', // NDM tenant
    'ndmi.annaguidance.ai': 'https://api.ndmi.annaguidance.ai', // NDMI tenant
    'smak.annaguidance.ai': 'https://api.smak.annaguidance.ai', // SMAK tenant
    'smct.annaguidance.ai': 'https://api.smct.annaguidance.ai', // SMC tenant (new domain)
    'annaguidance.ai': 'https://django-backend-604521917673.asia-northeast1.run.app', // Public tenant
    'localhost': 'http://127.0.0.1:8000', // Local development fallback
};

  return tenantMapping[hostname] || 'https://django-backend-604521917673.asia-northeast1.run.app'; // Default to public tenant
};

// Create Axios instance
const api = axios.create({
  baseURL: getBaseURL(), // Dynamically set the base URL
  headers: {
    'Content-Type': 'application/json',
    'X-Tenant-Domain': window.location.hostname, // Dynamically set tenant domain
  },
});

// Request Interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Add the `X-Tenant-Domain` header to identify the tenant domain
    config.headers['X-Tenant-Domain'] = window.location.hostname;

    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
// Response Interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      // Check if the error is from the token refresh endpoint
      if (originalRequest.url.includes('/api/token/refresh/')) {
        // Token refresh failure
        console.error('Token refresh failed:', error);
        toast.error('Session expired. Please log in again.');
        
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        
        // Force a reload (or redirect to login) here:
        window.location.reload();
        
        return Promise.reject(error);
      }

      // Prevent token refresh attempts for the login endpoint
      if (originalRequest.url.includes('/auth/token/')) {
        console.error(
          'Login failed:',
          error.response?.data?.detail || 'Unauthorized'
        );
        return Promise.reject(error);
      }

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('Refresh token not found');
        }

        // Attempt to refresh the token
        const refreshResponse = await axios.post(
          `${getBaseURL()}/api/token/refresh/`,
          {
            refresh: refreshToken,
          }
        );

        const newToken = refreshResponse.data.access;
        localStorage.setItem('authToken', newToken);

        // Retry the failed request with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        toast.error('Session expired. Please log in again.');
        
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        
        // Force a reload (or redirect to login) here as well:
        window.location.reload();
        
        return Promise.reject(refreshError);
      }
    } else {
      // Handle other error statuses
      toast.error(error.response?.data?.detail || 'An error occurred. Please try again.');
      return Promise.reject(error);
    }
  }
);


export default api;
