import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Table,
  Container,
  Form,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import api from "../../utils/api"; // API utility to handle requests
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Management = () => {
  const [sections, setSections] = useState([]);
  const [newSectionName, setNewSectionName] = useState("");
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSections, setSelectedSections] = useState([]); // For bulk actions
  const [students, setStudents] = useState([]); // For viewing students by section
  const [maxStarts, setMaxStarts] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [userSchoolId, setUserSchoolId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStudentsModal, setShowStudentsModal] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default: ascending
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(10); // Number of students per page
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = students.slice(indexOfFirstStudent, indexOfLastStudent);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSectionName, setSelectedSectionName] = useState(""); // State for section name
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);

  const filteredStudents = students.filter((student) =>
    student.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    student.id_no.includes(searchQuery)
  );
  
  // Paginate the filtered results
  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * studentsPerPage,
    currentPage * studentsPerPage
  );
  
  
  const handleCloseStudentsModal = () => {
    setCurrentPage(1); // Reset to the first page
    setShowStudentsModal(false); // Close the modal
  };
  
   // Add this function
   const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  
    if (!sections || sections.length === 0) {
      console.error("No data to sort");
      return;
    }
  
    const sortedSections = [...sections].sort((a, b) => {
      if (typeof a[field] === "string" && typeof b[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      }
      if (a[field] < b[field]) return order === "asc" ? -1 : 1;
      if (a[field] > b[field]) return order === "asc" ? 1 : -1;
      return 0;
    });
  
    setSections(sortedSections);
  };
  
  
  useEffect(() => {
    const fetchSchoolAndSections = async () => {
      setLoading(true); // Show loading spinner
      try {
        const userResponse = await api.get("/auth/user");
        const schoolId = userResponse.data.school_id;
        setUserSchoolId(schoolId);
  
        const response = await api.get(`/sections?school_id=${schoolId}`);
        setSections(response.data);
      } catch (err) {
        toast.error("Error fetching sections.", { position: "top-right" });
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };
  
    fetchSchoolAndSections();
  }, []);
  
  
// Add this function here
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page
  };

  const handleAddSection = async () => {
    if (!newSectionName.trim()) {
      toast.error("Section name cannot be empty.", { position: "top-right" });
      return;
    }
  
    if (sections.some(section => section.name.toLowerCase() === newSectionName.toLowerCase())) {
      toast.error("Section with this name already exists.", { position: "top-right" });
      return;
    }
  
    setLoading(true);
    try {
      const response = await api.post("/sections/add/", {
        name: newSectionName.trim(),
        school_id: userSchoolId,
      });
  
      setSections(prevSections => [...prevSections, response.data]);
      setNewSectionName("");
      toast.success("Section added successfully.", { position: "top-right" });
    } catch (err) {
      toast.error("Error adding new section.", { position: "top-right" });
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleEditSection = (section) => {
    setSelectedSection(section);
    setShowModal(true);
  };

  const handleSaveEdit = async () => {
    if (!selectedSection || !selectedSection.name) {
      toast.error("Section name cannot be empty.", { position: "top-right" });
      return;
    }
    setLoading(true);
  
    try {
      const response = await api.put(`/sections/${selectedSection.id}/`, {
        name: selectedSection.name,
        school_id: userSchoolId,
      });
  
      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === response.data.id ? response.data : section
        )
      );
  
      toast.success("Section updated successfully.", { position: "top-right" });
      setShowModal(false);
    } catch (err) {
      toast.error("Error updating section.", { position: "top-right" });
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleDeleteConfirmation = (section) => {
    setSectionToDelete(section);
    setShowDeleteModal(true);
  };

  const handleDeleteSection = async () => {
    setDeleting(true);
  
    try {
      await api.delete(`/sections/${sectionToDelete.id}/delete/`);
      setSections((prevSections) =>
        prevSections.filter((section) => section.id !== sectionToDelete.id)
      );
  
      toast.success("Section deleted successfully.", { position: "top-right" });
      setShowDeleteModal(false);
    } catch (err) {
      toast.error("Error deleting section.", { position: "top-right" });
    } finally {
      setDeleting(false);
    }
  };
  

  const handleSelectSection = (sectionId) => {
    setSelectedSections((prevSelected) =>
      prevSelected.includes(sectionId)
        ? prevSelected.filter((id) => id !== sectionId)
        : [...prevSelected, sectionId]
    );
  };

  const handleSelectAll = () => {
    if (selectedSections.length === sections.length) {
      setSelectedSections([]);
    } else {
      setSelectedSections(sections.map((section) => section.id));
    }
  };
  const handleToggleActive = async (userId, currentStatus) => {
    try {
      await api.patch(`/users/${userId}/activate/`, {
        is_active: !currentStatus, // Toggle the current status
      });
  
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === userId ? { ...student, is_active: !currentStatus } : student
        )
      );
  
      toast.success(
        `User has been ${!currentStatus ? "activated" : "deactivated"} successfully.`,
        { position: "top-right" }
      );
    } catch (err) {
      toast.error("Error updating active status.", { position: "top-right" });
    }
  };
  
  const handleBulkDelete = async () => {
    if (selectedSections.length === 0) {
      toast.error("No sections selected for deletion.", { position: "top-right" });
      return;
    }
  
    setDeleting(true);
  
    try {
      const response = await api.post("/sections/bulk-delete/", {
        ids: selectedSections,
      });
  
      setSections((prevSections) =>
        prevSections.filter((section) => !selectedSections.includes(section.id))
      );
      setSelectedSections([]); // Clear selection
      toast.success("Selected sections deleted successfully.", { position: "top-right" });
    } catch (err) {
      toast.error("Error deleting selected sections.", { position: "top-right" });
    } finally {
      setDeleting(false);
    }
  };
  
  
  
  const handleUpdateMaxStarts = async (userId) => {
    const newMaxStarts = maxStarts[userId];
  
    if (!newMaxStarts || parseInt(newMaxStarts) <= 0) {
      toast.error("Invalid max starts value.", { position: "top-right" });
      return;
    }
  
    try {
      await api.patch(`/users/${userId}/update-max-chatbot-starts/`, {
        max_chatbot_starts: newMaxStarts,
      });
  
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === userId
            ? { ...student, max_chatbot_starts: newMaxStarts }
            : student
        )
      );
  
      toast.success("Max chatbot starts updated successfully.", { position: "top-right" });
    } catch (err) {
      toast.error("Error updating max chatbot starts.", { position: "top-right" });
    }
  };
  
  const handleSortStudents = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  
    const sortedStudents = [...students].sort((a, b) => {
      if (typeof a[field] === "string" && typeof b[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      }
      if (a[field] < b[field]) return order === "asc" ? -1 : 1;
      if (a[field] > b[field]) return order === "asc" ? 1 : -1;
      return 0;
    });
  
    setStudents(sortedStudents);
  };
  
  const handleSortSections = (key) => {
    const sortedSections = [...sections].sort((a, b) =>
      a[key] > b[key] ? 1 : -1
    );
    setSections(sortedSections);
  };


  const handleViewStudents = async (sectionId) => {
    setLoading(true);
    try {
      const section = sections.find((sec) => sec.id === sectionId);
      setSelectedSectionName(section?.name || ""); // Set the section name for the modal title
  
      const response = await api.get(`/sections/${sectionId}/students/`);
      const sortedStudents = response.data
        .map((student) => ({
          ...student,
          has_taken_test: student.has_taken_test, // True if test is taken
          test_date: student.test_date
            ? new Date(student.test_date).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "Not Started",
        }))
        .sort((a, b) => {
          if (a.has_taken_test && !b.has_taken_test) return -1; // "Taken" first
          if (!a.has_taken_test && b.has_taken_test) return 1;
          return 0; // Maintain order within groups
        });
      setStudents(sortedStudents);
      setShowStudentsModal(true);
    } catch (err) {
      toast.error("Error fetching students for this section.", {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>Loading sections...</p>
      </div>
    );
  }
  
  if (!sections || sections.length === 0) {
    return (
      <div className="text-center mt-5">
        <Alert variant="info">No sections available for your school.</Alert>
      </div>
    );
  }
// Handle Bulk Delete Confirmation
const confirmBulkDelete = () => {
  if (selectedSections.length === 0) {
    toast.error("No sections selected for deletion.", { position: "top-right" });
    return;
  }
  setShowBulkDeleteModal(true);
};

// Handle Confirmed Bulk Delete
const handleConfirmedBulkDelete = async () => {
  setDeleting(true);
  try {
    await api.post("/sections/bulk-delete/", { ids: selectedSections });

    setSections((prevSections) =>
      prevSections.filter((section) => !selectedSections.includes(section.id))
    );
    setSelectedSections([]); // Clear selection
    toast.success("Selected sections deleted successfully.", { position: "top-right" });
    setShowBulkDeleteModal(false);
  } catch (err) {
    toast.error("Error deleting selected sections.", { position: "top-right" });
  } finally {
    setDeleting(false);
  }
};

  return (
    
    <Container className="my-4">
      <h2 className="text-center mb-4 fw-bold">Management Dashboard</h2>

      {/* Bulk Actions */}
      <Row className="mb-3">
  <Col>
  <Button
  variant="success"
  onClick={() => setShowAddModal(true)} // Open the modal
>
  Add Section
</Button>


<Button
  variant="danger"
  onClick={confirmBulkDelete} // Use the function that opens the modal
  disabled={selectedSections.length === 0 || deleting}
>
  {deleting ? <Spinner animation="border" size="sm" /> : "Delete Selected"}
</Button>



  </Col>
</Row>


      {/* Manage Sections */}
      <Row>
  <Col md={12}>
  <Card 
  className="mb-4" 
  style={{
    margin: "0 auto", // Center the card
    width: "fit-content", // Adjust card width to fit table content
    padding: "0", // Remove extra padding
    border: "none", // Remove any default border
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for better visibility
  }}
>
  <Card.Header
    className="text-center"
    style={{
      backgroundColor: "#f9c846", // Soft gold background for better contrast
      fontWeight: "bold",
      fontSize: "22px", // Slightly larger font size
      padding: "20px", // Increased padding for a more balanced look
      borderBottom: "2px solid #e5a700", // Add a border for visual separation
    }}
  >
    <span style={{ textShadow: "1px 1px 2px rgba(0,0,0,0.1)" }}>
      Manage Sections
    </span>
  </Card.Header>

  <Card.Body style={{ padding: "0" }}>
    <div
      style={{
        overflowX: "auto", // Allow horizontal scrolling for smaller screens
        backgroundColor: "#ffffff", // Ensure a clean white background
        borderRadius: "5px", // Add slight rounding to corners
      }}
    >
      <Table
        striped
        bordered
        hover
        responsive
        style={{
          margin: "0", // Align the table with the card
          borderCollapse: "collapse", // Ensure proper table structure
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Subtle shadow for depth
        }}
      >
        <thead
          style={{
            backgroundColor: "#f5f5f5", // Light gray for header background
            color: "#333", // Darker text for readability
            fontWeight: "bold", // Bold font for header text
          }}
        >
          <tr>
            <th className="text-center">
              <Form.Check
                type="checkbox"
                checked={selectedSections.length === sections.length}
                onChange={handleSelectAll}
              />
            </th>
            <th className="text-center">#</th>
            <th
              className="text-start"
              onClick={() => handleSort("name")}
              style={{ cursor: "pointer" }}
            >
              Section Name{" "}
              <i
                className={`bi ${
                  sortField === "name"
                    ? sortOrder === "asc"
                      ? "bi-arrow-up"
                      : "bi-arrow-down"
                    : "bi-arrow-down-up"
                }`}
                style={{
                  color: sortField === "name" ? "#007bff" : "inherit", // Highlight active column
                }}
              ></i>
            </th>
            <th
              className="text-center"
              onClick={() => handleSort("student_count")}
              style={{ cursor: "pointer" }}
            >
              Student Count{" "}
              <i
                className={`bi ${
                  sortField === "student_count"
                    ? sortOrder === "asc"
                      ? "bi-arrow-up"
                      : "bi-arrow-down"
                    : "bi-arrow-down-up"
                }`}
                style={{
                  color: sortField === "student_count" ? "#007bff" : "inherit",
                }}
              ></i>
            </th>
            <th
              className="text-center"
              onClick={() => handleSort("created_at")}
              style={{ cursor: "pointer" }}
            >
              Date Created{" "}
              <i
                className={`bi ${
                  sortField === "created_at"
                    ? sortOrder === "asc"
                      ? "bi-arrow-up"
                      : "bi-arrow-down"
                    : "bi-arrow-down-up"
                }`}
                style={{
                  color: sortField === "created_at" ? "#007bff" : "inherit",
                }}
              ></i>
            </th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sections.length > 0 ? (
            sections.map((section, index) => (
              <tr key={section.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedSections.includes(section.id)}
                    onChange={() => handleSelectSection(section.id)}
                  />
                </td>
                <td>{index + 1}</td>
                <td>{section.name}</td>
                <td>{section.student_count}</td>
                <td>
                  {section.created_at
                    ? new Date(section.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "N/A"}
                </td>
                <td className="text-center">
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="info"
                      size="sm"
                      className="me-2"
                      onClick={() => handleViewStudents(section.id)}
                    >
                      View Students
                    </Button>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEditSection(section)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteConfirmation(section)}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No sections available for your school.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  </Card.Body>
</Card>


  </Col>
</Row>
{/* Bulk Delete Confirmation Modal */}
<Modal show={showBulkDeleteModal} onHide={() => setShowBulkDeleteModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Bulk Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>
      Are you sure you want to delete the following sections? All users in these
      sections will have their section information removed. This action cannot
      be undone.
    </p>
    <ul>
      {sections
        .filter((section) => selectedSections.includes(section.id))
        .map((section) => (
          <li key={section.id}>
            <strong>{section.name}</strong> (ID: {section.id})
          </li>
        ))}
    </ul>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowBulkDeleteModal(false)}>
      Cancel
    </Button>
    <Button variant="danger" onClick={handleConfirmedBulkDelete} disabled={deleting}>
      {deleting ? <Spinner animation="border" size="sm" /> : "Delete"}
    </Button>
  </Modal.Footer>
</Modal>


      {/* Add Section Modal */}
<Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Add New Section</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group>
        <Form.Label>Section Name</Form.Label>
        <Form.Control
          type="text"
          value={newSectionName}
          onChange={(e) => setNewSectionName(e.target.value)}
          placeholder="Enter section name"
        />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
      Cancel
    </Button>
    <Button
      variant="success"
      onClick={handleAddSection}
      disabled={loading}
    >
      {loading ? <Spinner animation="border" size="sm" /> : "Add Section"}
    </Button>
  </Modal.Footer>
</Modal>


      {/* Edit Section Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Section Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedSection?.name || ""}
                onChange={(e) =>
                  setSelectedSection((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the section{" "}
          <strong>{sectionToDelete?.name}</strong>? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSection} disabled={deleting}>
            {deleting ? <Spinner animation="border" size="sm" /> : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
  show={showStudentsModal}
  onHide={handleCloseStudentsModal} // Use the function to close and reset pagination
  size="xl" // Make the modal wider
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Students in Section {selectedSectionName}</Modal.Title> {/* Dynamic Section Name */}
  </Modal.Header>
  <Modal.Body>
    {loading ? (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    ) : (
      <>
        {/* Search Input */}
        <Form.Control
          type="text"
          placeholder="Search by name or ID"
          value={searchQuery}
          onChange={handleSearchChange}
          className="mb-3"
        />

        {/* Students Table */}
        {filteredStudents.length > 0 ? (
          <>
            <Table striped bordered hover responsive>
            <thead>
  <tr>
    <th>#</th>
    <th onClick={() => handleSortStudents("id_no")} style={{ cursor: "pointer" }}>
      Student ID <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortStudents("full_name")} style={{ cursor: "pointer" }}>
      Full Name <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortStudents("grade_level")} style={{ cursor: "pointer" }}>
      Grade Level <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortStudents("has_taken_test")} style={{ cursor: "pointer" }}>
      Test Status <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortStudents("chatbot_starts")} style={{ cursor: "pointer" }}>
      Chatbot Usage <i className="bi bi-arrow-down-up"></i>
    </th>
    <th className="text-center" onClick={() => handleSortStudents("max_chatbot_starts")} style={{ cursor: "pointer" }}>
      Max Chatbot Usage <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortStudents("is_active")} style={{ cursor: "pointer" }}>
      Account Status <i className="bi bi-arrow-down-up"></i>
    </th>
    <th>Actions</th>
  </tr>
</thead>

<tbody>
  {paginatedStudents.map((student, index) => (
    <tr key={student.id}>
      <td>{(currentPage - 1) * studentsPerPage + index + 1}</td>
      <td>{student.id_no}</td>
      <td>{student.full_name}</td>
      <td>{student.grade_level}</td>
      <td>
        {student.has_taken_test ? (
          <span className="text-success">Taken - {student.test_date}</span>
        ) : (
          <span className="text-danger">Not Taken</span>
        )}
      </td>
      <td>
        <span
          className={
            student.chatbot_starts >= student.max_chatbot_starts
              ? "text-danger fw-bold"
              : "text-success fw-bold"
          }
        >
          {student.chatbot_starts}/{student.max_chatbot_starts}{" "}
          {student.chatbot_starts >= student.max_chatbot_starts
            ? "(Maxed Out)"
            : `(Remaining: ${student.max_chatbot_starts - student.chatbot_starts})`}
        </span>
      </td>
      <td className="text-center">
  <div className="d-flex flex-column align-items-center">
    <Form.Control
      type="number"
      min="1"
      value={maxStarts[student.id] || student.max_chatbot_starts}
      onChange={(e) =>
        setMaxStarts((prev) => ({
          ...prev,
          [student.id]: e.target.value,
        }))
      }
      style={{ width: "80px", textAlign: "center" }}
    />
    <Button
      variant="primary"
      size="sm"
      className="mt-1"
      onClick={() => handleUpdateMaxStarts(student.id)}
      disabled={
        !maxStarts[student.id] ||
        parseInt(maxStarts[student.id]) === student.max_chatbot_starts
      }
    >
      Update
    </Button>
  </div>
</td>

      <td>
        {student.is_active ? (
          <span className="text-success fw-bold">Active</span>
        ) : (
          <span className="text-danger fw-bold">Inactive</span>
        )}
      </td>
      <td>
        <Button
          variant={student.is_active ? "danger" : "success"}
          size="sm"
          onClick={() => handleToggleActive(student.id, student.is_active)}
        >
          {student.is_active ? "Deactivate" : "Activate"}
        </Button>
      </td>
    </tr>
  ))}
</tbody>



            </Table>

            {/* Pagination Controls */}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Button
                variant="secondary"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                Previous
              </Button>
              <span>
                Page {currentPage} of {Math.ceil(filteredStudents.length / studentsPerPage)}
              </span>
              <Button
                variant="secondary"
                disabled={currentPage >= Math.ceil(filteredStudents.length / studentsPerPage)}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <p className="text-center">No students found matching your search criteria.</p>
        )}
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseStudentsModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>



    </Container>
  );
};

export default Management;
